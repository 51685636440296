.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

textarea{
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  padding: 12px 20px;
  box-sizing: border-box;
  resize: none;
}

.icon{
  width: 64px;
  height: 64px;
}


/* button{
  background-color: #007bff;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  margin-right: 5px;
  padding: 6px 12px;
  text-decoration: none;
} */
.start-page {

  /* padding: 20px; */
  /* background-color: #ffffff; */
  /* border-radius: 8px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* text-align: center; */
}
.page-title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
}


/* in place */
/* .user-input.normal {  
  position: relative;
  top: 0px;
  width: 50%;
  height: 100%;
  bottom: 95%;

} */
@keyframes moveToBottomAndExpandWidth {
  from {
    position: relative;
    top: 0px;
    width: 50%;
    height: 100%;
    bottom: 95%;
  }
  /* expand */
  to {
    position: relative;
    top: 55px;
    width: 90%;
    height: 100%;
    bottom: 95%;
        
  }
}

/* .user-input.fixedToBottom {
  position: relative;

  top: 55px;
  width: 90%;
  height: 100%;
  animation-name: moveToBottomAndExpandWidth;
  animation-duration: 1s;
  
} */
/* .submit-button{
  display: inline-block;
  margin-top: 10px;
  font-size: 16px;
  color: #ffffff;
  background-color: #007bff;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;  
  
}
.submit-button.normal {
  opacity: 1;
}

.submit-button.fixedToBottom {

  opacity: 0;
  animation-name: dissapearButton;
  animation-duration: 0.3s;
} */


@keyframes dissapearButton {
  from {
    opacity: 1;

  }
  to {
    opacity: 0;

        
  }
}

/* .submit-button:hover {
  background-color: #0056b3;
} */
.content-container{
  overflow: hidden;
  text-align: left;
  width: 100%;

}

.content-container.normal {
  max-height: 0px;
  height: 0px;
  opacity: 0;
  

}

.content-container.fixedToBottom {
  max-height: 11500px;
  opacity: 1;  

  animation-name: appearContent;
  animation-duration: 5s;
}

@keyframes appearContent {
  from {
    max-height: 200px;
    opacity: 0;

  }
  to {
    max-height: 11500px;
    opacity: 1;
  }
}

img{
  max-height:512px;
}

.shopping-page {
  padding: 20px;
background-color: #ffffff;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.page-title {
font-size: 24px;
font-weight: bold;
color: #333333;
margin-bottom: 20px;
}
.personalized-message {
font-size: 16px;
color: #444444;
margin-bottom: 10px;
}
.product-item {
font-size: 16px;
color: #444444;
margin-bottom: 10px;
border: 1px solid #cccccc;
padding: 10px;
border-radius: 4px;
}
.product-title {
font-weight: bold;
}
.product-price {
color: #007bff;
}
.compatibility-info {
font-size: 14px;
color: #228B22; /* Forest green */
margin-top: 5px;
}
.actions {
font-size: 14px;
margin-top: 5px;
}
.action-item {
display: inline-block;
padding: 6px 12px;
margin-right: 5px;
font-size: 14px;
color: #ffffff;
background-color: #007bff;
border-radius: 4px;
cursor: pointer;
text-decoration: none;
}
.action-item:hover {
background-color: #0056b3;
}

.MuiDrawer-docked button.MuiButtonBase-root h6{
  /* display: none; */
  font-size: 15px;
  margin: -5px;

}



.start-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}
.breadcrumbs {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.breadcrumbs span {
  cursor: pointer;
}

.advanced-panel h3 {
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.advanced-panel {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.start-page .user-input {
  width: 80%;
  height: 200px;
  /* resize: vertical; */
  padding: 0.5rem;
  
  margin-top: 0.5rem;

}

.start-page .submit-button.fixedToBottom {
  background-color: #3f753f;
  border-radius: 4px;
  border: none;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  /* display: inline-block; */
  font-size: 48px;
  margin: 0.5rem 0;
  width: 18%;
  margin-left: 4px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  color: #ffffff;
  font-weight: bold;
  /* width: 100%; */
  min-height: 200px;

  height: auto;
  /* margin-top: 0.5rem; */
  /* min-height: 200px; */
  /* resize: vertical; */
  position: absolute;
}
.start-page .submit-button:hover{
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.6);
}
.start-page .submit-button:active{
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.mainpanel{
  margin-top: 10px;
  margin-bottom: 10px;

}
.content-container{
  min-height: 100px;
  width: 98%;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.content-container{
  min-height: 100px;
  width: 98%;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-right: 10px;


}
.start-page .submit-button:disabled{
  background-color: #cccccc;
  color: #666666;
  box-shadow: none;
  cursor: not-allowed;
}




.partial-screen .panel {
  background-color: rgba(0, 0, 0,0.2);
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0.1px;
  padding-bottom: 0.1px;
  border-radius: 5px;
  box-shadow: #282c34 0px 0px 5px 0px;

  width: 100%;
  min-width: 750px;
}
.collapsable .header{
  cursor: pointer;
}

.small-button-at-upper-right{
  font-size: 15px;
  background-color: transparent;
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0px;
  border-bottom: 1px solid #007bff;
  border-right: 1px solid #007bff;
  border-top: 1px solid #007bff;
  border-left: 1px solid #007bff;
  /* padding: 5px; */
  margin: 2px;
  position: absolute;
  top: 0px;
  right: 0px;
  /* font-size: 15px; */
  cursor: pointer;
  /* display: inline-block; */
  padding: 0px 0px;
  text-decoration: none;
}

.full-screen .user-input-panel{
  display: none;
}

.full-screen .advanced-panel{
  display: none;
}

/* 
.MuiToolbar-root:has(div div button div) {
  width:0px;
}

.MuiToolbar-root:has(.MuiIconButton-root) {
  width:0px;
} */